<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "manage-users",
              "edit-user",
              "assign-resources-to-user"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper level-search" v-if="search">
        <div class="search-field">
          <form @submit.prevent="fetchResources" novalidate>
            <input
              type="text"
              ref="searchForm"
              @keyup="setSearchQuery($event.target.value)"
              :placeholder="
                displayLabelName('manage-users', 'edit-user', 'search')
              "
            />
            <div class="icon search">
              <icon icon="#cx-app1-search-16x16" width="16" height="16" />
            </div>
            <button class="clear-field" @click="clearSearch" type="button">
              <div class="svg-icon">
                <svg>
                  <use xlink:href="#cx-app1-cross-round"></use>
                </svg>
              </div>
            </button>
          </form>
        </div>
      </section>
    </template>
    <ul
      v-if="levelResources && levelResources.data && levelResources.data.length"
      class="clebex-item-section pill"
    >
      <li
        class="clebex-item-section-item"
        v-for="levelResource in levelResources.data"
        :key="levelResource.id"
      >
        <router-link
          class="clebex-pill-link"
          :to="{
            name: 'r_edit-user-add-resources-to-user-resource-mode',
            params: { resourceId: levelResource.id }
          }"
        >
          <button class="clebex-item-content-wrapper">
            <span class="label">
              <span class="text">
                <span class="highlight">{{ levelResource.name }}</span>
                <span
                  v-if="
                    levelResource.full_path && levelResource.full_path.length
                  "
                  >, {{ levelResource.full_path.join(", ") }}</span
                >
              </span>
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </button>
        </router-link>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "AddAssignableResourcesToUserResources",
  data() {
    return {
      processing: false,
      show: false,
      search: null
    };
  },
  created() {
    this.fetchResources();
  },
  watch: {
    routeParam(value) {
      if (value) {
        this.fetchResources();
      }
    }
  },
  computed: {
    ...mapState("level", ["levelResources", "frequentLevelResources"]),
    routeParam() {
      const { levelId } = this.$route.params;
      return levelId;
    }
  },
  methods: {
    ...mapActions("level", ["getLevelResources"]),
    toggleSearch() {
      this.search = !this.search;
    },
    fetchResources() {
      this.getLevelResources({
        levelId: this.routeParam,
        params: {
          includes: ["all_parents", "full_path", "is_assigned"],
          sort: "FAVORITES"
        }
      });
    },
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    },
    setSearchQuery(inputValue) {
      this.$store.commit("search/setSearchQuery", inputValue, {
        root: true
      });
    },
    clearSearch() {
      this.$refs.searchForm.value = "";
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.fetchResources();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  beforeUnmount() {
    this.$store.commit("level/setLevelResources", null, { root: true });
    this.$store.commit("search/setSearchQuery", "", {
      root: true
    });
  }
};
</script>
